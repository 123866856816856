import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../components/layout'
import Metatags from '../components/Metatags'


const KerjaMail = () => {

    const data = useStaticQuery(graphql`
        query kerjaquery {
            HomeBanner: file(
                absolutePath: {regex: "/images/kerjamail/"}
                name: {eq: "home_banner"}
                ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            Awesome: file(
                absolutePath: {regex: "/images/kerjamail/"}
                name: {eq: "awesome"}
                ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }
        }
    `)

    const homeBanner = getImage(data.HomeBanner)
    const awesome = getImage(data.Awesome)

    return (
        <>         
            <Layout isIntegrationPage={true}>

                <Metatags
                    title="KerjaMail + Optinly"
                    description="Purchase KerjaMail plan from Optinly to get up to 50 GB of free email space."
                />


                {/* Intro Section */}
                <section className='w-full py-10 md:py-12 xl:py-14 home-banner-background'>
                    <div className='flex flex-col w-4/5 mx-auto space-y-8 xl:flex-row xl:items-center xl:space-y-0 xl:space-x-5'>
                        
                        {/* Quotes */}
                        <div className='flex flex-col items-start w-full space-y-6 xl:w-3/5'>
                            <div className='flex flex-col items-start space-y-4'>
                                <span className={`text-2xl md:text-28px lg:text-32px font-poppins xl:leading-45px font-semibold tracking-wide capitalize`}><span className='text-primary'>Get upto 50GB free</span> ad-free email hosting space from KerjaMail on any purchase Optinly of starter or growth plan</span>

                                {/* CTA Image Mobile View */}
                                <div className='w-full xl:hidden'>
                                    <GatsbyImage image={homeBanner} alt='Ecommerce Email Marketing' className='w-auto h-auto' />
                                </div>
                            </div>

                            <div className='flex flex-col items-start space-y-5'>
                                <a href="https://app.optinly.com/" target="_blank" className="px-6 py-2.5 text-button_text font-semibold text-center text-sm md:text-base lg:text-lg bg-custom_yellow rounded-lg focus:outline-none hover:text-button_text home-button-shadow">Grow Your  Email List </a>

                                <small className='text-xs font-medium text-font_primary font-poppins'><span className='font-semibold'>*No creadit card</span> is required</small>
                            </div>
                        </div>

                        {/* CTA Image Desktop View */}
                        <div className='hidden w-full xl:block xl:w-2/5'>
                            <GatsbyImage image={homeBanner} alt='Ecommerce Email Marketing' className='w-auto h-auto' />
                        </div>

                    </div>
                </section>

                {/* Whats awesome section */}
                <section className='flex flex-col items-center justify-center w-4/5 mx-auto my-10 space-y-3 xl:my-28'>
                    <h2 className='text-2xl font-semibold md:text-28px lg:text-32px text-font_primary font-poppins'>What's Awesome About This?</h2>
                    <p className='w-full mx-auto text-sm text-center break-words xl:w-1/2 md:text-base xl:text-lg text-secondary font-roboto'>Create a professional email address@yourdomain.com and receive upto 50 GB of free email space when you purchase a plan from optinly. With Kerjamail built-in internal broadcast feature, you can send emails to everyone on your team fast and efectively</p>
                    <div className='flex items-center justify-center w-full py-12 mx-auto '>
                        <GatsbyImage image={awesome} alt='Ecommerce Email Marketing' className='w-auto h-auto' />
                    </div>
                </section>

                {/* Comparision Section */}
                <section className='flex flex-col items-center justify-center w-4/5 mx-auto my-4 xl:my-16'>
                    <h2 className='text-2xl font-semibold text-center md:text-28px lg:text-32px text-font_primary font-poppins'>Take Advantage Of Both Optinly + Kerjamail</h2>

                    <div className='w-full py-12 mx-auto xl:w-full 2xl:w-4/5 md:py-16 xl:py-24'>
                        <div className='flex flex-col items-center px-8 py-4 bg-white md:px-12 md:py-6 xl:px-20 xl:py-10 xl:flex-row rounded-10px md:rounded-20px xl:rounded-40px' style={{border: "1px solid #E3E5E8"}}>

                            <div className='w-full xl:w-1/2 mx-auto flex flex-col space-y-3.5 '>

                                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_324_85)">
                                    <path d="M35.9729 8.60048C35.6345 8.79061 35.315 9.02186 35.0224 9.29323L33.3992 10.7985L41.2016 17.878C42.2936 18.8689 43.1232 20.13 43.6006 21.5251C44.078 22.9202 44.195 24.4252 43.939 25.8774L39.4691 51.227C38.7302 55.4171 35.1087 58.4584 30.8579 58.4584C30.3491 58.4584 29.8359 58.4134 29.3326 58.3246L27.9076 58.0734L28.224 59.8672C28.6516 62.2932 30.7612 64 33.1421 64C33.4302 64 59.8675 59.365 59.8675 59.365C62.587 58.8855 64.4029 56.2922 63.9232 53.5727L57.6851 18.195C57.3915 16.5294 56.2756 15.1251 54.7195 14.4627L40.3806 8.35898C40.1557 8.26323 39.9262 8.18585 39.6944 8.12473L39.073 4.23598L38.6485 1.57948C38.485 0.556855 37.5232 -0.139395 36.5011 0.0238554C35.4785 0.18723 34.7821 1.14861 34.9455 2.17123L35.3597 4.76323L35.9729 8.60048Z" fill="#006BFF"/>
                                    <path d="M19.4743 29.6486L20.4993 23.8356C20.479 23.8378 20.4595 23.8394 20.4391 23.8418C19.0915 24.0017 18.2448 25.0301 17.9018 25.9392C17.534 26.9138 17.6353 27.8868 18.166 28.4786C18.4999 28.8508 18.9273 29.2341 19.4743 29.6486Z" fill="#F8C201"/>
                                    <path d="M21.2845 33.7792L19.9373 41.4198C21.767 41.2227 23.0541 39.8937 23.5419 38.5196C23.782 37.8431 24.3659 35.5228 21.7966 34.0712C21.6211 33.9722 21.4506 33.8748 21.2845 33.7792Z" fill="#F8C201"/>
                                    <path d="M22.8453 9.09558C22.5648 9.15545 22.287 9.2387 22.0154 9.34845L7.59514 15.1761C5.99676 15.8221 4.84389 17.2458 4.54451 18.9436L0.0767611 44.2812C-0.402739 47.0007 1.41314 49.594 4.13251 50.0735L29.9838 54.6317C30.2775 54.6835 30.5695 54.7085 30.8579 54.7085C33.2385 54.7085 35.3484 53.0016 35.776 50.5758L40.2459 25.2261C40.5441 23.5348 39.9535 21.809 38.6816 20.655L27.2485 10.2811C27.0259 10.0791 26.7878 9.90058 26.5384 9.7447L27.2175 5.8932L27.8686 2.2007C28.0484 1.18095 27.3675 0.208452 26.3478 0.0287023C25.3284 -0.151673 24.3555 0.529702 24.1758 1.54958L23.5246 5.24258L22.8453 9.09558ZM23.693 20.1203L23.4349 21.5846C25.1311 22.0826 26.0785 23.083 26.5318 23.5618C26.5951 23.6288 26.6608 23.6981 26.6921 23.7271C27.199 24.1957 27.23 24.9866 26.7614 25.4935C26.2928 26.0003 25.502 26.0313 24.995 25.5627C24.9025 25.4772 24.812 25.3816 24.7163 25.2805C24.38 24.9252 23.8746 24.3926 22.9968 24.0692L21.7479 31.1518C22.1405 31.3872 22.565 31.6338 23.0265 31.8946C25.8195 33.4726 26.9466 36.4013 25.8979 39.3558C25.3828 40.807 24.3859 42.062 23.0906 42.8895C22.0025 43.5847 20.7765 43.9417 19.4919 43.9457L19.1759 45.7375C19.069 46.344 18.5415 46.7706 17.9464 46.7706C17.8743 46.7706 17.8013 46.7643 17.7279 46.7515C17.048 46.6316 16.594 45.9833 16.7139 45.3035L17.0133 43.6057C15.3816 43.2421 14.2065 42.6956 12.5864 41.1746C12.083 40.7021 12.058 39.9111 12.5306 39.4077C13.0031 38.9043 13.7941 38.8795 14.2975 39.352C15.4879 40.4695 16.2301 40.8626 17.4476 41.1432L18.9943 32.3718C17.7933 31.57 16.9505 30.8671 16.3053 30.1478C15.1489 28.8587 14.8645 26.9078 15.563 25.0566C16.3314 23.02 18.0871 21.6032 20.145 21.3592C20.4233 21.3262 20.6896 21.307 20.9471 21.2973L21.2313 19.6862C21.351 19.0063 21.9994 18.5525 22.6793 18.6722C23.3589 18.7921 23.8129 19.4405 23.693 20.1203Z" fill="#FAD207"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_324_85">
                                    <rect width="64" height="64" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>

                                <h3 className='mt-4 text-xl font-semibold capitalize md:text-2xl text-28px text-font_primary font-poppins'>Choose the plan that works for you</h3>

                                <p className='text-xs font-medium md:text-sm font-poppins text-font_primary'><span className='text-sm font-semibold md:text-base text-primary'>Save upto 20% off</span></p>
                            </div>

                            <div className='flex flex-col w-full mx-auto my-4 space-y-4 xl:w-1/2 md:my-6 xl:my-0 xl:flex-row md:space-y-6 xl:space-y-0 xl:space-x-10'>

                                <div className='flex flex-col items-center justify-center w-full py-4 text-center bg-white rounded-10px md:rounded-14px xl:rounded-20px' style={{boxShadow: "0px 14px 64px rgba(55, 81, 116, 0.1)"}}>

                                    <svg className='pt-2' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 17C20.912 17 24.0834 13.8287 24.0834 9.91671C24.0834 6.00469 20.912 2.83337 17 2.83337C13.088 2.83337 9.91669 6.00469 9.91669 9.91671C9.91669 13.8287 13.088 17 17 17Z" stroke="#006BFF" strokeWidth="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M29.1691 31.1667C29.1691 25.6842 23.715 21.25 17 21.25C10.285 21.25 4.83081 25.6842 4.83081 31.1667" stroke="#006BFF" strokeWidth="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    <h5 className='pt-3.5 text-base md:text-lg xl:text-xl text-font_primary font-poppins font-semibold'>
                                        Starter
                                    </h5>

                                    <h3 className='py-2 text-xl font-semibold capitalize md:text-2xl text-28px text-primary font-poppins'>$29.50</h3>

                                    <div className='w-full flex flex-col items-center space-y-0.5'>
                                        <small className='text-xs font-medium text-secondary font-poppins'>(Monthly)</small>
                                        <p className='w-full text-sm font-medium text-black md:text-base font-poppins'>10GB Email, 30GB</p>
                                    </div>
                                </div>

                                <div className='flex flex-col items-center justify-center w-full py-4 text-center bg-white rounded-10px md:rounded-14px xl:rounded-20px' style={{boxShadow: "0px 14px 64px rgba(55, 81, 116, 0.1)"}}>

                                    <svg className='pt-2' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 17C20.912 17 24.0834 13.8287 24.0834 9.91671C24.0834 6.00469 20.912 2.83337 17 2.83337C13.088 2.83337 9.91669 6.00469 9.91669 9.91671C9.91669 13.8287 13.088 17 17 17Z" stroke="#006BFF" strokeWidth="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M29.1691 31.1667C29.1691 25.6842 23.715 21.25 17 21.25C10.285 21.25 4.83081 25.6842 4.83081 31.1667" stroke="#006BFF" strokeWidth="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    <h5 className='pt-3.5 text-base md:text-lg xl:text-xl text-font_primary font-poppins font-semibold'>
                                        Growth
                                    </h5>

                                    <h3 className='py-2 text-xl font-semibold capitalize md:text-2xl text-28px text-primary font-poppins'>$29.50</h3>

                                    <div className='w-full flex flex-col items-center space-y-0.5'>
                                        <small className='text-xs font-medium text-secondary font-poppins'>(yearly)</small>
                                        <p className='w-full text-sm font-medium text-black md:text-base font-poppins'>25GB Email, 50GB</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                
            </Layout>
        </>
    )
}

export default KerjaMail
